<template>
  <div class="login-page d-flex justify-content-center align-items-center">
    <div class="card login-card">
      <div class="card-body">
        <div class="d-flex justify-content-center align-items-center title">
          Mão Amiga
        </div>
        <div class="form-group">
          <label>Login</label>
          <input type="text" v-model="user.login" v-on:keyup.enter="login()" class="form-control" />
        </div>
        <div class="form-group">
          <label>Senha</label>
          <input type="password" v-model="user.password" v-on:keyup.enter="login()" class="form-control" />
        </div>
        <button class="btn btn-primary float-right" v-on:click="login()">Entrar</button>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/api/auth";
import Swal from "sweetalert2";
import { bus } from "@/main";

export default {
  name: "login",
  data(){
    return {
      user: {},
    }
  },
  methods: {
    login: function(){
      if (this.user.login != "" && this.user.password != "") {
        bus.$emit("changeLoading", true);
        auth
          .login(this.user.login, this.user.password, false)
          .then(response => {
            window.location.href = "/dashboard/clients";
          })
          .catch(error => {
            Swal.fire({
              title: "Tivemos um problema",
              text: "Parece que essas credencias não constam no sistema.",
              icon: "error"
            });
          })
          .finally( () => {bus.$emit("changeLoading", false)})
      } else {
        Swal.fire({
          title: "Atenção",
          text: "Preencha todos os campos.",
          icon: "warning"
        });
      }      
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/general.scss";

.login-page {
  height: 100vh;
  padding-bottom: 20vh;
  background-color: $page-background;
  .login-card {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    height: fit-content;
    width: 40vw;
    max-width: 500px;
    min-width: 400px;
    .title{
      font-size: 28px;
      padding: 10px 0 15px 0;
      color: $primary-red;
      font-weight: 500;
    }
    input{
      text-align: center;
    }
    .logo {
      width: 14vw;
      min-width: 180px;
      margin-bottom: 4vh;
    }
  }
}
</style>